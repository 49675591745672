import { request } from '@midwayjs/hooks/request'


export  function getReportList (...args) {
  return request({
    url: '/api/report/getReportList',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-report-getreportlist",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

export  function getReport (...args) {
  return request({
    url: '/api/report/getReport',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-report-getreport",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

